<template>
  <div>
    <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area ptb--60 bg_image bg_image--27"
      data-black-overlay="7"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center blog-single-page-title pt--100">
              <h1 class="heading-title theme-gradient">
                Configuration Alexa
              </h1>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->
    <!-- Start Blog Area  -->
    <div class="rn-blog-area ptb--60 bg_color--5">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div>
              <v-stepper non-linear alt-labels v-model="e1">
                <v-stepper-header>
                  <v-stepper-step editable step="1">
                    Activation de Mes amis de confiance
                  </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step editable step="2">
                    Configuration de Mes amis de confiance
                  </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step editable step="3">
                    Routine
                  </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step editable step="4">
                    Suivi des alerts
                  </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step editable step="5">
                    Ajout d'amis
                  </v-stepper-step>
                </v-stepper-header>
                <v-stepper-items>
                  <v-stepper-content step="1">
                    lien vers tuto installation Alexa<br /><br /><br />
                    Activer la skill sur Alexa sur le portail Alexa<br /><a
                      target="_blank"
                      href="https://alexa-skills.amazon.fr/apis/custom/skills/amzn1.ask.skill.143b926e-dc46-4c97-a979-026b6f3004ba/launch"
                    >
                      <img
                        alt="just ask"
                        src="../../assets/images/logo/logo_alexa.png"
                        style="max-width:150px"
                    /></a>
                  </v-stepper-content>
                  <v-stepper-content step="2">
                    <v-carousel v-model="model">
                      <v-carousel-item v-for="(img, i) in activation" :key="i">
                        <v-sheet :color="img" height="100%" tile>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                          >
                            <div class="text-h2">
                              <img :src="img" width="300" />
                            </div>
                          </v-row>
                        </v-sheet>
                      </v-carousel-item>
                    </v-carousel>
                  </v-stepper-content>
                  <v-stepper-content step="3">
                    <div
                      class="thumb position-relative"
                      style="max-width:600px"
                    >
                      <div class="video-responsive">
                        <iframe
                          width="560"
                          height="315"
                          src="https://www.youtube.com/embed/lU4PIGFXRbQ"
                          frameborder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </div>
                    </div>
                  </v-stepper-content>
                  <v-stepper-content step="4">
                    dddd
                  </v-stepper-content>
                  <v-stepper-content step="5">
                    eeeee
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Blog Area  -->
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      e1: 1,
      steps: 4,
      model: 0,
      activation: [
        require("../../assets/images/installation/alexa/activation2.png"),
        require("../../assets/images/installation/alexa/parametre.png"),
        require("../../assets/images/installation/alexa/choix.png"),
        require("../../assets/images/installation/alexa/accountlinking-aut.png"),
        require("../../assets/images/installation/alexa/infoperso.png"),
        require("../../assets/images/installation/alexa/friend.png"),
        require("../../assets/images/installation/alexa/done.png")
      ]
    };
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    }
  },
  methods: {
    nextStep(n) {
      if (n === this.steps + 1) {
        this.e1 = 1;
      } else {
        this.e1 = n;
      }
    },
    end() {
      this.$router.push({ name: "Dashboard" });
    }
  }
};
</script>
